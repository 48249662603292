import React, { FC } from 'react';
import styled from '@emotion/styled';

import { FontPrimaryRegular, FontSecondarySemiBold } from '../../../styles/fonts';
import { Icon } from '../Icons/Icon';
import { Tooltip } from 'antd';
import { sortPricesByDays } from '../../../helper/prices-helper';
import { readablePrice } from '../../../helper/readable-price';
import { spacingBottom, spacingRight, spacingTop } from '../../../helper/spacing';
import { mediaQueries } from '../../../styles/media-queries';
import {UrlObject} from 'url';
import { useRouter } from 'next/router';
import styles from './product-price.module.scss';
import { ProductFees } from '../../product/product-fees';

export interface IProductPrice {
  price: string;
  days: number;
}

enum PriceTypeEnum {
  Eq = 'EQ',
  Min = 'MIN'
}

export interface IProductPriceInformation {
  productPricePerDay: {
    amount: number;
    type: PriceTypeEnum;
  };
  productPriceReducedPerDay: {
    amount: number;
    type: PriceTypeEnum;
  };
  workingDays: number;
  serviceFees: {
    key: string;
    value: number;
  }[];
}

export interface IProductPriceProps {
  prices: IProductPrice[];
  productPriceInformation?: IProductPriceInformation | null;
  renderTooltipDays: (days: number) => React.ReactElement;
  pricePerDayLabel: string;
  fromLabel: string;
  detailsLabel?: string;
  link: UrlObject | null;
  serviceFees: {
    key: string;
    value: number;
  }[];
}

export const ProductPrice: FC<IProductPriceProps> = ({
  prices, productPriceInformation, pricePerDayLabel, fromLabel, renderTooltipDays, detailsLabel, link, serviceFees
}) => {
  sortPricesByDays(prices);

  const generalPrice = prices[prices.length - 1];

  const tooltipContent: React.ReactElement[] = prices.map((price, index) => (
    <PriceString key={`tooltip-element-${index}`}>
      <PriceInfo>
        {price.price}
      </PriceInfo>
      <StyledDelimiter/>
      <DaysInfo>
        {renderTooltipDays(price.days)}
      </DaysInfo>
    </PriceString>
  ));

  const fromPrice = <>{fromLabel} {generalPrice.price}{' '}</>;
  const detailedPrice = <>
    {(productPriceInformation?.productPricePerDay.amount ?? 0) === (productPriceInformation?.productPriceReducedPerDay.amount ?? 0)
      ? <>
        {PriceTypeEnum.Min === productPriceInformation?.productPriceReducedPerDay.type && (<>ab</>)} {readablePrice(productPriceInformation?.productPriceReducedPerDay.amount ?? 0)} / {pricePerDayLabel}
      </>
      : (<>
        <OldPrice>statt {readablePrice(productPriceInformation?.productPricePerDay.amount ?? 0)} / {pricePerDayLabel}</OldPrice>
        {readablePrice(productPriceInformation?.productPriceReducedPerDay.amount ?? 0)} / {pricePerDayLabel}
      </>)
    }
  </>;

  return (
    <div className={styles.productPrice}>
      <div className={styles.priceInfoColumn}>
        <DetailedPricesTooltip
          tooltipContent={<>{tooltipContent}</>}
          detailsLabel={detailsLabel}
          link={link ?? null}
        />
      </div>
      <GeneralPriceColumn>
        {!productPriceInformation ? fromPrice : detailedPrice}

      </GeneralPriceColumn>
      <ProductFees serviceFees={serviceFees} />
    </div>
  );
};

interface IDetailedPricesTooltipProps {
  tooltipContent: React.ReactElement;
  detailsLabel?: string;
  link: UrlObject | null;
}

const DetailedPricesTooltip: FC<IDetailedPricesTooltipProps> = (props) => {
  const { tooltipContent, link } = props;
  const { push } = useRouter();

  return (
    <StyledTooltip placement="bottomRight" title={tooltipContent} arrow>
      <span onClick={() => link && push(link)}>
        <span className={styles.detailsLabel}>{props.detailsLabel}</span>
        <Icon icon={'info-outline'} size={'17px'}/>
      </span>
    </StyledTooltip>
  );
};

const GeneralPriceColumn = styled.div`
    ${spacingTop(1)};
    ${FontSecondarySemiBold};
    font-size: var(--font-size-headline-h4);
    line-height: 24px;
    color: var(--black);
    white-space: nowrap;
    ${spacingRight(0.5)};

    @media ${mediaQueries.laptopL} {
      ${spacingRight(0)};
      ${spacingBottom(1.5)};

      &:last-of-type {
        ${spacingRight(0.5)};
      }
    }
`;

const OldPrice = styled.div`
  ${FontSecondarySemiBold};
  font-size: var(--font-size-text-small);
  line-height: 14px;
  text-align: right;
  text-decoration: line-through;
`;

const PriceString = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
`;

const StyledTooltip = styled(Tooltip)`
  ${FontPrimaryRegular};
  align-items: center;
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
  }
`;

const StyledDelimiter = styled.hr`
  width: 35px;
`;

const PriceInfo = styled.span`
  font-size: var(--font-size-text-small);
  line-height: 20px;
  white-space: nowrap;
`;

const DaysInfo = styled.span`
  font-size: var(--font-size-text-small);
  line-height: 20px;
`;
