/*
t('service-fees:title.crane_charges')
t('service-fees:title.assembly_costs')
t('service-fees:title.incidental_expenses')
t('service-fees:title.cleaning_costs')
t('service-fees:title.return_inspection')
t('service-fees:title.preparation_costs')
t('service-fees:title.service_charges')
 */
import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';

export const ServiceFeeTitle: FC<{feeType: string}> = ({ feeType }) => {
  const [t] = useTranslation(['service-fees']);

  return (
    <>{t(`service-fees:title.${feeType}`)}</>
  );
};
