import React, { FC, memo } from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import {
  FontPrimaryRegular,
  FontPrimarySemiBold,
  FontSecondaryLight,
  readablePrice,
  spacingAround,
} from '@digando/react-component-library';
import { ServiceFeeTitle } from '../../helper/service-fee-title-by-key';

interface IProductServiceFee {
  key: string;
  value: number;
}
interface IProductFees {
  serviceFees: IProductServiceFee[];
}

export const ProductFeesComponent: FC<IProductFees> = ({ serviceFees }) => {
  const [t] = useTranslation(['checkout-page']);


  if (serviceFees.length === 0) {
    return null;
  }

  return (
    <ProductFeesContainer className={'serviceFee'}>
      <Title>{t('tenant-service-fees')}</Title>
      {serviceFees.map((fee) => (
        <FeeContainer key={fee.key}>
          <FeeTitle><ServiceFeeTitle feeType={fee.key} /></FeeTitle>
          <FeeValue>{readablePrice(fee.value)}</FeeValue>
        </FeeContainer>
      ))}
    </ProductFeesContainer>
  );
};

const ProductFeesContainer = styled.div`
  width: 100%;
  align-self: center;
`;

const Title = styled.p`
  ${FontSecondaryLight};
  font-size: var(--font-size-text-small);
`;

const FeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const FeeTitle = styled.p`
  ${FontPrimaryRegular};
  font-size: var(--font-size-text);
`;

const FeeValue = styled.p`
  ${FontPrimarySemiBold};
  font-size: var(--font-size-text);
  ${spacingAround(0)}
`;


export const ProductFees = memo(ProductFeesComponent);

